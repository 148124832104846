.distributor_invite {
    text-align: center;
    width: 100%;
    margin: 0 auto;
    min-height: 100vh;
    background-color: #F5F5F5;
}

.distributor_invite_header_box {
    position: relative;
    text-align: left;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    max-width: 375px;
    padding-top: 20px;
    padding-left: 20px;
    padding-bottom: 146px;
    background-color: #F0F6F6;
}

.distributor_invite_reward_info_bg1 {
    position: absolute;
    background-color: #FF2E01;
    height: 112px;
    width: calc(100% - 24px);
    left: 12px;
    bottom: 0;
    border-radius: 7px;
}

.distributor_invite_reward_info_bg2 {
    text-align: center;
    position: absolute;
    background-color: white;
    height: 127px;
    width: calc(100% - 54px);
    left: 27px;
    border-radius: 10px;
    bottom: 0;
}

.distributor_invite_reward_info_bg2_top {
    display: inline-block;
    background-color: #FFECC4;
    border-radius: 0 0 6px 6px;
    padding: 2px 10px;
    font-size: 14px;
    margin-bottom: 12px;
}

.distributor_invite_reward_info_bg2_describe {
    font-size: 17.5px;
    color: #FC172E;
}

.distributor_invite_reward_info_bg3 {
    position: absolute;
    background-image: url(../../assets/image/distributor/red_poject_bg.png);
    background-repeat: no-repeat;
    background-size: 100% 100%;
    width: calc(100% - 24px);
    left: 12px;
    height: 60px;
    bottom: 0;
}

.distributor_invite_reward_info_bg3_time {
    margin-top: 28px;
    text-align: center;
    font-size: 12px;
    color: white;
}

.distributor_invite_header_img {
    background-image: url(../../assets/image/distributor/advantage_image.png);
    background-repeat: no-repeat;
    background-size: 100% 100%;
    width: 148px;
    height: 90px;
}

.distributor_invite_register_box {
    margin: 12px;
    background-color: white;
    padding: 15px 17px 15px 15px;
    border-radius: 9px;
}


.distributor_invite_input_box {
    text-align: left;
    display: flex;
    justify-content: space-between;
    justify-items: center;
    background-color: white;
    width: 100%;
    height: 40px;
    line-height: 40px;
}

.ant-input:placeholder-shown {
    font-size: 16px;
    color: #666666;
}

.distributor_invite_input {
    display: inline-block;
    font-size: 16px;
    height: 26px;
    width: 170px;
    padding: 0 20px;
    margin: 16px 0;
    border: none;
}

.distributor_invite_get-code-begin {
    display: inline-block;
    border: 1px solid #E0E0E0;
    color: #000000;
    font-size: 14px;
    margin-top: 10px;
    line-height: 30px;
    padding: 0 9px;
    height: 31px;
    border-radius: 5px;
}

.distributor_invite_get-code-after {
    display: inline-block;
    border: 1px solid #E0E0E0;
    color: #999999;
    font-size: 14px;
    margin-top: 10px;
    line-height: 30px;
    padding: 0 9px;
    height: 31px;
    border-radius: 5px;
}

.distributor_invite_input,
.distributor_invite_input:hover,
.distributor_invite_input:active,
.distributor_invite_input:focus {
    border: none;
    box-shadow: none;
}


.distributor_invite_button {
    margin-top: 30px;
    font-size: 16px;
    font-weight: 500;
    padding: 11px 0;
    height: 44px;
    width: 100%;
    border-radius: 8px;
}

.distributor_invite_button,
.distributor_invite_button:hover,
.distributor_invite_button:active,
.distributor_invite_button:focus {
    background-color: #FFA11A;
    border: none;
    color: #333333;
}
