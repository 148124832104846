.user_invite {
    text-align: center;
    width: 100%;
    margin: 0 auto;
    min-height: 100vh;
    background-color: #FCE2A0;
}

.toast_box {
    text-align: center;
    position: fixed;
    display: flex;
    width: 100%;
    max-width: 375px;
    justify-content: center;
    top: calc(100vh/2);
}

.user_invite_main {
    position: relative;
    width: 100%;
    margin: 0 auto;
    min-height: 800px;
    color: #F43644;
    background-image: url(../../assets/image/receive_bg.png);
    background-repeat: no-repeat;
    background-size: 100% 730px;
    background-color: #FCE2A0;
}

.user_invite_main_title {
    position: absolute;
    top: 300px;
    left: calc(100%/2 - 99px);
    width: 198px;
    font-weight: bold;
    font-size: 33px;
}

.user_invite_money {
    padding-top: 190px;
}

.user_invite_footer {
    width: 350px;
    position: absolute;
    top: 580px;
    left: calc(100%/2 - 350px/2);
}

.user_invite_button_image {
    width: 350px;
    height: calc(148px/2);
    background-image: url(../../assets/image/user_button.png);
    background-size: 350px calc(148px/2);
    background-repeat: no-repeat;
    line-height: 67px;
    font-size: 23px;
    color: #fff;
}

.user_invite_input_box {
    text-align: left;
    display: flex;
    justify-content: space-between;
    background-color: white;
    width: calc(100% - 14px);
    height: 50px;
    line-height: 50px;
    border-radius: 30px;
    padding: 0 5px;
    margin-left: 7px;
    margin-bottom: 10px;
}

.ant-input:placeholder-shown {
    font-size: 18px;
    color: #666666;
}

.user_invite_input {
    display: inline-block;
    font-size: 18px;
    height: 25px;
    width: 170px;
    padding: 0 20px;
    margin: 14px 0;
    border: none;
}
.user_invite_input_box input::-webkit-input-placeholder {
    color: #666666;
    font-size: 18px;
}

.get-code-begin {
    padding-right: 15px;
    color: #FFA11A;
    font-size: 18px;
}

.get-code-after {
    padding-right: 15px;
    color: #999999;
    font-size: 18px;
}

.user_invite_input,
.user_invite_input:hover,
.user_invite_input:active,
.user_invite_input:focus {
    border: none;
    box-shadow: none;
}
