.toast__mask {
    display: none;
    background: transparent;
}
.toast__toast--show {
    display: block;
}
.toast__info {
    position: absolute;
    top: 50%;
    left: 50%;
    padding: 10px 20px;
    transform: translate(-50%, -50%);
    border-radius: 5px;
    background-color: rgba(0, 0, 0, 0.6);
    color: #fff;
}
